import React from "react";
import { Link } from "gatsby";
import Image from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  SubTitle,
  Text,
  Wrapper,
  SecondWrapper,
  DotIcon,
  ListBox,
  StyledImg,
  NumberedBox,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const EarlyTreatment = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="">
        <BreadcrumbItem Link="/what-we-do" Text="What We Do" />
        <BreadcrumbItem
          Link="/what-we-do/early-treatment"
          Text="Early Treatment"
        />
      </Breadcrumb>
      <BannerImages imagesrc="earlyTreatment" />
      <PageContainer>
        <Container>
          <Title>Early Treatment</Title>
          <>
            <Text>
              Our team at Gippsland Orthodontics encourage parents to bring
              their children in for an early assessment to ensure a healthy
              smile. The Australian Society of Orthodontists and Orthodontics
              Australia recommend that children be seen by a specialist
              orthodontist between the ages of 7 to 10 years. During this time,
              kids will have a mix of baby and adult teeth, and this helps us
              determine if there are any developing concerns.
            </Text>
            <Text>
              Early intervention treatment can play a vital role in childhood
              development. Therefore, early evaluation is crucial to assist in
              correcting growth and jaw development, with the potential of
              reducing more serious orthodontic treatment in the future.
              Starting this review process between the ages of 7 to 10 years,
              doesn’t always mean treatment straight away, it means that we are
              able to diagnose potential problems and recommend the best time to
              start treatment. In some cases, monitoring and periodic evaluation
              is most appropriate.
            </Text>
            <Text>
              Don’t rely on the untrained eye, it cannot always spot orthodontic
              issues.
            </Text>
          </>
          <Wrapper>
            <SubTitle>
              What are some signs that my child may need early orthodontic
              treatment?
            </SubTitle>
            <ContentsContainer>
              <Text>
                Some signs that your child might need orthodontic treatment may
                be super easy to spot... other signs may be a little more
                subtle. Below are some of the signs to look out for:
              </Text>
              <NumberedBox>
                <p>1. Premature loss of “baby teeth”</p>
                <Text className="text">
                  Early loss of teeth, either through trauma, decay or poor oral
                  hygiene, may lead to movement of other teeth into the space of
                  the lost tooth. This can then lead to other problems and
                  become a much harder problem to fix.
                </Text>
              </NumberedBox>
              <NumberedBox>
                <p>2. Teeth are crooked</p>
                <Text className="text">
                  Possibly the easiest problem to spot! If teeth are crooked,
                  angled the wrong way, overlapping or rotated then some
                  intervention may be required.
                </Text>
              </NumberedBox>
              <NumberedBox>
                <p>3. Speech impediment</p>
                <Text className="text">
                  Difficulty speaking, a lisp or problems with the tongue or
                  lips, may be caused by misaligned jaws or crooked teeth.
                  Sometimes this is corrected by orthodontics, a speech
                  pathologist or a combination of both.
                </Text>
              </NumberedBox>
              <NumberedBox>
                <p>4. Have an overbite, underbite, open bite or crossed bite</p>
                <Text className="text">
                  These result from a tooth misalignment or a jaw discrepancy.
                  They arise from many situations including habits (including
                  thumb sucking), trauma or genetic factors.
                </Text>
              </NumberedBox>

              <NumberedBox>
                <p>5.Snorer, mouth breather or have sleep apnoea</p>
                <Text className="text">
                  Kids shouldn’t snore at a young age. Snoring or poor sleep
                  habits may be a sign that orthodontic intervention may be
                  required. In many cases, orthodontic treatment is managed with
                  other team members including ENT (Ear, Nose and Throat)
                  specialist and a Sleep Physician.
                </Text>
              </NumberedBox>

              <Text>
                If any of these above issues are relevant to your child, please
                don’t place your child’s orthodontic visit to the back of your
                to-do list, as your little one’s health and smile is a priority.
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.girl2} />
            </ImageContainer>
          </Wrapper>
          <>
            <SubTitle>What can early orthodontics fix?</SubTitle>

            <Text>
              Early orthodontic treatment begins while a child’s jaw bones are
              still soft as the corrective procedures work faster and can be
              more effective than in teens and adults. Early intervention
              usually means that later orthodontic treatment will be more
              straightforward, shorter in duration and less complicated. The
              benefits of early orthodontics include:
            </Text>
            <ListBox>
              <DotIcon />
              <p>Create room to reduce chances of crowdings</p>
            </ListBox>
            <ListBox>
              <DotIcon />
              <p>Maintain space to reduce unwanted tooth movement</p>
            </ListBox>
            <ListBox>
              <DotIcon />
              <p>
                Address damaging dental habits (thumb/digit or pacifier sucking)
              </p>
            </ListBox>
            <ListBox>
              <DotIcon />
              <p>Potential decreased chance of future extractions</p>
            </ListBox>
            <ListBox>
              <DotIcon />
              <p>Guide adult teeth into alignment</p>
            </ListBox>
            <ListBox>
              <DotIcon />
              <p>Decrease chances of complicated future treatments</p>
            </ListBox>
            <br />
            <Text>
              At Gippsland Orthodontics we offer a range of early treatment
              options to help children achieve their best oral health. We also
              offer treatments that make sure your child feels as comfortable
              and happy as possible while visiting us.
            </Text>
            <SubTitle>What treatment options are available?</SubTitle>
            <Text>
              After the initial comprehensive assessment with Gippsland
              Orthodontics, you will likely leave with an idea of what is
              required – either now, or in the future. This will also include an
              idea on treatment options, timeframes of treatment and costs. Some
              treatment options are, but are not limited to the following:
            </Text>
          </>
          <SecondWrapper>
            <ImageContainer>
              <StyledImg fluid={image.boysGirlsSmiles} />
            </ImageContainer>
            <ContentsContainer>
              <SubTitle>Expansion devices</SubTitle>
              <Text>
                These devices are used to expand the upper dental arch, creating
                more room for teeth.
              </Text>

              <SubTitle>Space maintainers</SubTitle>
              <Text>
                If a child loses a tooth too early – it is important to continue
                to maintain the spot for the permanent teeth, which is exactly
                what a space maintainer does.
              </Text>

              <SubTitle>
                Correcting bad habits – including thumb, digit and pacifier
                habits
              </SubTitle>
              <Text>
                The orthodontist can provide treatment to correct bad habits,
                such as thumb sucking. Thumb sucking is a common occurrence in
                young children, however if it persists, it can cause a range of
                dental and mouth issues.
              </Text>

              <SubTitle>Tooth extraction</SubTitle>
              <Text>
                May be required to ensure permanent teeth emerge correctly.
              </Text>

              <SubTitle>Early braces</SubTitle>
              <Text>
                Sometimes a few braces will be used to correct teeth growing in
                the wrong direction (for example, impacted molar teeth),
                overbites and underbites. These are generally on for a short
                period of time.
              </Text>
            </ContentsContainer>
          </SecondWrapper>
          <>
            <SubTitle>So, what’s next?</SubTitle>
            <Text>
              If you want to learn more about early interceptive orthodontics,
              or think you are ready to bring your child for their first
              appointment, then get in contact with the team at Gippsland
              Orthodontics <Link to={`/contact`}>here</Link>.
            </Text>
          </>
        </Container>
      </PageContainer>
    </>
  );
};

export default EarlyTreatment;
