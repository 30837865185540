import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import EarlyTreatment from "../../components/service-items/early-treatment";
import { colors } from "../../styles/variables";

const EarlyTreatmentPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="Early Treatment" />
    <EarlyTreatment />
  </Layout>
);

export default EarlyTreatmentPage;
